import React, { Component } from "react"
import ContextProvider from "../components/context/contextProvider"
import PressPage from "../components/press-page/pressPage"
import { createStore } from "redux"
import { Provider } from "react-redux"
import allReducer from "../reducers"
import "../styles/shared.scss"
import NavbarRegion from "../components/navbar/NavbarRegion"

let store
if (typeof window !== "undefined") {
  store = createStore(
    allReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
} else {
  store = createStore(allReducer)
}

class Press extends Component {
  render() {
    return (
      <ContextProvider>
        <Provider store={store}>
          <NavbarRegion hideAuth={true} />
          <PressPage
            langSaved={
              this.props.location.state ? this.props.location.state.lang : null
            }
          />
        </Provider>
      </ContextProvider>
    )
  }
}

export default Press
