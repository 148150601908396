import React from "react"
import "./pressPage.scss"
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';
class PressPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <main className="pressPage">
        <div className="titles">
          <h1>Press</h1>
        </div>

        <div className="content">
          <ul>
            <li>
              <a href={`../../20210403_QVAX_Q&A_PRESS_NL.pdf`} download>
                06/04/2021 - QVAX_Q&A_PRESS_NL
              </a>
            </li>
            <li>
              <a href={`../../20210403_QVAX_Q&A_PRESS_FR.pdf`} download>
                06/04/2021 - QVAX_Q&A_PRESS_FR
              </a>
            </li>
            <li>
              <a href={`../../Sreenshots_press_FR.zip`} download>
                03/04/2021 - Sreenshots_press_FR
              </a>
            </li>
            <li>
              <a href={`../../Sreenshots_press_NL.zip`} download>
                03/04/2021 - Sreenshots_press_NL
              </a>
            </li>
            <li>
              <a
                href={`../../03-04-2021-QVAX_ stepbystep_Press_FR.pdf`}
                download
              >
                03/04/2021 - QVAX_ stepbystep_Press_FR
              </a>
            </li>
            <li>
              <a
                href={`../../03-04-2021-QVAX_ stepbystep_Press_NL.pdf`}
                download
              >
                03/04/2021 - QVAX_ stepbystep_Press_NL
              </a>
            </li>
            <li>
              <a href={`../../logo_qvax.svg`} download>
                03/04/2021 - QVAX Logo SVG
              </a>
            </li>
            <li>
              <a href={`../../logo_qvax.jpg`} download>
                03/04/2021 - QVAX Logo JPG
              </a>
            </li>
          </ul>
        </div>
      </main>
    )
  }
}

export default PressPage
